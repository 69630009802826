.patreon-section-wrapper {
    min-height: 50vh;
}

.patreon-text-wrapper {
    width: 20rem;
}

.patreon-text {
    text-align: left !important;
    text-shadow: 0 0 5px #ebebeb, 0 0 10px #ebebeb, 0 0 15px #ebebeb,
        0 0 20px #ebebeb, 0 0 30px #ebebeb, 0 0 40px #ebebeb;
}

.patreon-logo {
    width: 10rem;
    height: 4.9rem;
    margin-left: 0.7rem;
    background-image: url("/site/img/patreon-vector-logo.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

.p-ba {
    line-height: 2rem;
}

.patreon-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vw;
    z-index: -20;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    background-color: $colorLightGray;

    &-image {
        background-image: url("/site/img/mogacs_tamogatas.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 30rem;
        height: 30rem;
        background-position-x: center;
        background-position-y: center;
        margin-top: auto;
        position: absolute;
        bottom: 0;
        right: -8rem;

        @media (min-width: 576px) and (min-height: 576px) {
            width: 35rem;
            height: 35rem;
        }

        @media (min-width: 768px) and (min-height: 768px) {
            width: 45rem;
            height: 45rem;
        }

        @media (min-width: 768px) {
            right: -10rem;
        }

        @media (min-width: 992px) {
            right: -4rem;
        }

        @media (min-width: 1200px) {
            right: 0rem;
        }
    }
}
