.card {
    min-height: 100%;
    margin-bottom: 1.5rem;

    &-body {
        position: relative;
    }

    &-link {
        position: absolute;
        bottom: 1.25rem;
        right: 1.25rem;
    }

    &-dark {
        color: $colorLightGray;
        background-color: $colorBlack;
        border: 1px solid darken($colorLightGray, 10%);
    }
}

.card-image {
    height: 400px;
    width: 100%;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @include media-breakpoint-up(md) {
        height: 200px;
    }
}
.zoom-in {
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
    }
}

.zoom-in {
    &:focus,
    &:hover {
        &::after {
            transform: scale(1.05);
        }
    }
}
