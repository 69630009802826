$shadowHeight: 2rem;
$shadowOp: .15;

.shadow-up {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: $shadowHeight;
    background-image: linear-gradient(rgba(0,0,0,$shadowOp), rgba(0,0,0,0));
}

.shadow-dn {
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height: $shadowHeight;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,$shadowOp));
}

.anchr-wrapper{
    position: relative;
}

.anchr{
    position: absolute;
    top: 0;

    @media (min-width: 992px) {
        top: -55px;
    }
}
