.profile {
    min-height: 80vh;
    background-color: #f8f9fa ;
    .card{
        border: 2px solid black;
        border-radius: 15px;
        .card-header{
            background-color:white ;
            border-bottom: none;
        }
    }
    .form-control {
        border: 2px solid black;
        border-radius: 0.75rem;
        color: black;

        &::placeholder {
            color: black;
        }
    }
    label{
        text-transform: uppercase;
        font-weight: bold;
    }
    .btn-save{
        font-weight: bold;
        text-transform: uppercase;
        &:hover{
            text-decoration: none;
        }
    }
}
