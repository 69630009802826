.membership-img {


    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
    }
    @include media-breakpoint-up(xl) {
        max-width: 75%;
    }
    @media (min-width: 1450px) {
        max-width: 65%;
    }


}

.membership-card {
    background-color: #ebebeb !important;
    border-color: #ebebeb !important;
    padding: 2rem;

    .card-title {
        font-weight: bold;
        text-align: center;
    }

    .membership-level {
        margin-bottom: 3rem;
    }

    .membership-time {
        display: block;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
    }

    .subscribe-button {
        background-color: #101010;
        border-color: #101010;
        color: #ebebeb;
        padding: 0.5rem 1rem;
        display: block;
        min-width: 75%;
        font-size: 20px;
        border-radius: 5px;
        margin: 2rem auto;
    }


    .membership-rights {
        list-style-type: none;
        font-size: 1rem;
        font-weight: bold;

        li {
            position: relative;
            margin-bottom: 10px;

            &:before {
                content: '';
                width: 15px;
                height: 15px;
                background-color: #101010;
                position: absolute;
                left: -25px;
                top: 5px;
                border-radius: 50%;
            }
        }
    }

}

.membership-checkbox {
    color: #ebebeb;

    a {
        color: #ebebeb;
        text-decoration: underline;

        &:hover {
            color: #ebebeb;
        }
    }

    &::before {
        background-color: transparent;
        border-radius: 0px !important;
        border: 1px solid #ebebeb
    }

    &::after {
        background-color: transparent;
        border: 1px solid #ebebeb
    }
}

.membership-text {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;


    &--light {
        color: #ebebeb;
        text-align: left;
        font-weight: normal;
        font-style: italic;

    }

    &__price {
        font-style: normal;
        font-weight: bold;
    }

    &:last-of-type {
        margin-bottom: 2rem;
    }
}

.submit-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.membership-submit {
    padding: 0.5rem 2rem;
    background-color: #ebebeb;
    color: #101010;
    border: none;
    font-size: 18px;
    font-weight: bold;
    margin-left: auto;
}

.membership-bg-image {
    color: #ebebeb;
    background-image: url(../img/stabtag_fizetes_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


}


.subscribe-result {
    .membership-bg-image {
        min-height: 80vh;
    }

}

.membership-btn {
    padding: 0.5rem 1.5rem;
    background-color: #ebebeb;
    color: #101010;
    border: none;
    border-radius: 0;
    font-size: 18px;
    font-weight: bold;
    display: block;
    min-width: 120px;
    margin: auto;

    &:hover {
        text-decoration: none;
    }

    &--dark {
        background-color: #101010;
        color: #ebebeb !important;

        &:hover {
            color: #ebebeb !important;
        }
    }

    &--sm {
        font-size: 14px;
        padding: 0.5rem 1rem;
    }

}
