.contact-section-wrapper {
    min-height: 100vh;
    @media (min-width: 992px) {
        min-height: calc(100vh - 10.5rem);
    }
}

.contact-text-wrapper {
    max-width: 33rem;
}

.contact-patreon {
    margin-bottom: 0;
    line-height: 5rem;
    padding-right: 0.5rem;
}

.contact-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vw;

    z-index: -10;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    background-color: #ebebeb;
    &-image {
        background-image: url("/site/img/mogacs_feliratkozas.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        margin-top: auto;
        position: absolute;
        width: 13rem;
        height: 34rem;
        right: -5rem;
        bottom: -7rem;

        @media (min-height: 635px) {
            width: 15rem;
            height: 39rem;
            right: -4.9rem;
            bottom: -9rem;
        }

        @media (min-width: 400px) and (min-height: 725px) {
            width: 16.2rem;
            height: 43rem;
        }

        @media (min-width: 425px) and (min-height: 845px) {
            width: 19.2rem;
            height: 49rem;
            right: -1rem;
            bottom: -17rem;
        }

        @media (min-width: 768px) and (min-height: 780px) {
            width: 22.2rem;
            height: 58rem;
            bottom: -13rem;
            right: -4.5rem;
        }

        @media (min-width: 768px) and (min-height: 1000px) {
            width: 26.2rem;
            height: 66rem;
            right: -6.5rem;
            bottom: -12rem;
        }

        @media (min-width: 992px) and (min-height: 700px) {
            width: 24rem;
            height: 61rem;
            right: 0rem;
            bottom: -21rem;
        }

        @media (min-width: 992px) and (min-height: 1360px) {
            width: 36.2rem;
            height: 95rem;
            right: -5.5rem;
            bottom: -28rem;
        }

        @media (min-width: 1200px) {
            width: 26rem;
            height: 68rem;
            right: 2rem;
            bottom: -26rem;
        }

        @media (min-width: 1200px) and (min-height: 1000px) {
            width: 33.2rem;
            height: 84rem;
            right: 3.5rem;
            bottom: -30rem;
        }

        /*
      width: 30rem;
      height: 77rem;
      right: 13rem;
      */
    }
}
