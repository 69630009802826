.lecture-live {
    &--footer {
        display: flex;

        .left {
            background-color: #101010;
            color: #fff;

            img {
                max-width: 40%;
            }

            .text-box {
                display: inline-block;
                margin-left: 25px;

                .btn {
                    border-radius: 0;
                    font-weight: bold;
                    padding: 6px 20px;
                }
            }
        }

        .right {
            padding-left: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("../img/mogacs_tamogatas.png");
            background-position: 115% 87%;
            background-size: 50%;
            background-repeat: no-repeat;

            .text-box {
                width: 60%;
                @include media-breakpoint-up(lg) {
                    width: 45%;
                }

                .btn {
                    float: right;
                }
            }
        }
    }
}
.next-btn{
    border-radius: 0;
    font-weight: bold;
    padding: 6px 20px;
    display: inline-block;
}
