.navbar {
    padding: 0;

    @media (min-width: 992px) {
        padding: 0.5rem 1rem;
    }
}

.navbar-nav {
    transition: all 0.4s ease;
    padding: 1rem;
    padding-right: 6rem;

    @media (min-width: 992px) {
        padding: 0;
    }
}

.nav-link {
    color: rgba($colorBlack, 0.85) !important;
    font-weight: 700;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    text-align: center;

    &:hover {
        color: $colorBlack !important;
        text-decoration: underline;
    }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: $colorLightGray !important;
    background-color: $colorBlack;
}

.navbar-toggler {
    background-color: $colorLightGray;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.navbar-light .navbar-toggler .collapsed {
    background-color: white;
}

.navbar-light .navbar-toggler {
    color: $colorBlack;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    padding: 0.5rem 0.6rem;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("/site/img/hamburger-icon.svg");
}
.nav-item {
    &.subscribe {
        padding: 3px;
        max-width: 160px;
        background-color: rgb(210, 210, 210);
        border-radius: 10px;
        height: 37px !important;
        .nav-link-custom {
            display: block;
            padding-left: 5px;
            padding-right: 5px;
            clear: both;
            color: rgba($colorBlack, 0.85) !important;
            font-weight: 700;
            padding-left: 0.8rem !important;
            padding-right: 0.8rem !important;
            text-align: center;

            &:hover {
                color: $colorBlack !important;
                text-decoration: underline;
            }
            .subscribe-menu {
                display: inline-block;
                width: auto;

                .phone-icon {
                    float: left;
                    width: 30px;
                    height: 30px;
                    background-image: url("/site/img/phone-icon.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 35px auto;
                }
                .nav-title {
                    margin-top: 5px;
                    float: left;
                }
                @media (min-width: 992px) and (max-width: 1200px) {
                    .phone-icon {
                        display: none !important;
                    }
                }
            }
        }
    }
}
