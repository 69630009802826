.youtube-section {
    background-color: white;
}

.youtube {
    background-image: url("/site/img/Logo_of_YouTube_\(2015-2017\).svg");
    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 35vw;

    @media (min-width: 576px) {
        height: 6rem;
    }

    @media (min-width: 768px) {
        background-size: 55% auto;
    }

    @media (min-width: 992px) {
        background-size: 42% auto;
    }
}

.mm-logo-sprite {
    background-image: url("/site/img/MM-logo.gif");
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 24vw;

    @media (min-width: 576px) {
        height: 6rem;
    }
}

.btn-youtube {
    font-size: 3rem;

    @media (min-width: 576px) {
        font-size: 2rem;
    }
}
