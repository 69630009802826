.card-webshop {
    cursor: pointer;

    .card-image {
        overflow: hidden;
        position: relative;

        .opcio {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba($colorBlack, 0.68);
            color: white;

            .cart {
                background-image: url("/site/img/webshop/cart.png");
                background-repeat: no-repeat;
                background-size: 1.8rem auto;
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &-content {
            transition: all 0.3s ease;
        }
    }

    &:hover .card-image-content {
        transform: scale(1.2);
    }
}
