.img-thumbnail {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 0;
    margin-bottom: 0.5rem;
}

.gallery-column-left {
    >.thumbnail > .img-thumbnail {
        height: 11rem;
    }
}

.gallery-column-mid {
    > .row {
        height: 11rem;

        @media (min-width: 768px) {
            height: 50%;
        }

        > .col-6 {
            height: 10.5rem;

            @media (min-width: 768px) {
                height: calc(100% - 0.5rem);
            }
        }
    }

    >.thumbnail {

         height: 11rem;

        @media (min-width: 768px) {
            height: 50%;
        }

        > .img-thumbnail {
            height: 10.5rem;

            @media (min-width: 768px) {
                height: calc(50% - 0.5rem);
            }
        }
    } 
}

.gallery-column-right {
    > .thumbnail > .img-thumbnail {
        height: calc(100% - 0.5rem);
    }
}

//--- modal

.modal-content {
    border-radius: 0;
    border: 1px solid darken($colorLightGray, 35%);
    box-shadow: 0 0 180px rgba(black, .5);
}

.close {
    position: absolute;
    top: 7px;
    right: 11px;
    color: white;
    text-shadow: none;
    opacity: 1;
    font-weight: 900;
}

.gallery-nav-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    > .btn {
        font-size: 1rem;
        height: 100%;
    }

    > .btn-secondary {
        background-color: rgba($colorBlack, .25);
        border: none;
        border-radius: 0;
        transition: all .4s ease;

        &:active, &:visited {
            background-color: rgba($colorBlack, .35);
        }

        &:hover {
            background-color: rgba(125,125,125, .5);
            color: black;
        }

        &:focus {
            box-shadow: 0 0 0 0 rgba(0,0,0,0);
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgba($colorBlack, .45);
    border-color: rgba($colorBlack, .45);
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
}