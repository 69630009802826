.footer-logo {
    background-size: 3rem 3rem;
    width: 3rem;
    height: 3rem;
    opacity: 0.68;
    transition: all 0.3s ease;

    &:hover {
        opacity: 1;
    }

    &-insta {
        background-image: url("/site/img/Insta-logo.svg");
    }

    &-facebook {
        background-image: url("/site/img/fb-logo.svg");
    }

    &-twitter {
        background-image: url("/site/img/Twitter-logo.svg");
    }

    &-youtube {
        background-image: url("/site/img/youtube-logo.svg");
    }
}
