.btn {
    &:hover {
        text-decoration: underline;
    }
}

button:focus {
    outline: none;
}

.btn-danger {
    color: $colorLightGray;
    background-color: $colorDanger;
    border-color: $colorDanger;
    font-weight: 900;

    &:hover {
        color: white;
        background-color: darken($colorDanger, 10%);
        border-color: darken($colorDanger, 10%);
    }
}

.card-link {
    color: $colorLightGray;
    background-color: $colorBlack;
    border: 1px solid white;
    font-weight: 900;
    padding: 0.5rem 1.2rem;
    letter-spacing: 0.05rem;
    transition: all 0.2s ease;

    &:hover {
        color: $colorBlack;
        background-color: white;
        border: 1px solid rgba($colorBlack, 0.6);
    }

    &-white {
        color: $colorBlack;
        background-color: $colorLightGray;
        border: 1px solid black;

        &:hover {
            color: $colorLightGray;
            background-color: $colorBlack;
            border: 1px solid rgba($colorLightGray, 0.5);
        }
    }
}

.btn-dark {
    color: $colorLightGray;
    background-color: $colorBlack;
    border: 1px solid white;
    font-weight: 900;
    padding: 0.5rem 1.2rem;
    letter-spacing: 0.05rem;
    border-radius: 0;

    &:hover {
        color: $colorBlack;
        background-color: white;
        border: 1px solid rgba($colorBlack, 0.6);
    }
}
