.img-col {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

.patreon-text-wrapper {
    width: 25rem;
}

.section-heading.patreon-text {
    font-size: 2.5rem;
}

.patreon-text {
    font-size: 16px;
}

.footer-logo.big {
    width: 6rem;
}

.footer-logo-twitch {
    background-image: url("/site/img/twitch-logo.svg") !important;
}

.footer-logo-tiktok {
    background-image: url("/site/img/tiktok.svg") !important;
}

.footer-logo-wiki {
    background-image: url("/site/img/wikipedia.png") !important;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.footer-logo-patreon {
    background-image: url("/site/img/patreon-vector-logo-white.svg") !important;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.card-link.full {
    right: auto;
    left: auto;
    bottom: auto;
    top: auto;
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    margin: 0px !important;
}

.card-link.left {
    left: 1.25rem !important;
    right: auto !important;
}

.img-col {
    background-size: contain;
    background-position: center center;
}

#floating-social {
    position: fixed;
    top: 150px;
    right: 0px;
    z-index: 99999;
    height: 230px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

/*
#floating-social:hover{
	width: 100px !important;
	-webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
*/

#floating-social.close_btn {
    width: 30px;
}

#floating-social.open_btn {
    width: 100px !important;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

#floating-social .box {
    position: relative;
    height: 230px;
}

#floating-social .box #arrow {
    width: 30px;
    height: 40px;
    background-color: black;
    position: absolute;
    left: 0px;
    top: calc(50% - 20px);
    background-image: url("/site/img/left-arrow.svg");
    background-size: 15px auto;
    background-position: center center;
    background-repeat: no-repeat;
}

#floating-social.open_btn .box #arrow {
    background-image: url("/site/img/right-arrow.svg");
}

#floating-social.open_btn .box #arrow {
    background-image: url("/site/img/right-arrow.svg");
}

#floating-social .box #social {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 70px;
    height: 230px;
    background-color: black;
    position: relative;
    left: 30px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

#floating-social .box #social .floating-logo {
    display: block;
    width: 50px;
    height: 50px;
    margin: auto;
}

.floating-logo.footer-logo-twitch {
    background-size: 35px auto;
    background-position: center center;
    background-repeat: no-repeat;
}

.floating-logo.footer-logo-tiktok {
    background-size: 35px auto;
    background-position: center center;
    background-repeat: no-repeat;
}

#floating-social .box #social .floating-logo {
    opacity: 0.6;
}

#floating-social .box #social .floating-logo:hover {
    opacity: 1;
}

.error-field {
    border-bottom: 1px solid red !important;
}

.color-light-gray {
    color: rgb(163, 163, 163) !important;
}

.nav-link {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
    font-size: 14px !important;
}

.m-w-100 {
    max-width: 100% !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    border-color: black;
    box-shadow: none;
    outline: 0 none;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    i {
        background-color: #101010;
        color: white;
        padding: 4px;
        margin-left: 8px;
    }
}

.dropdown-menu {
    border-radius: 1.125rem;
    text-align: center;
    border: 2px solid #101010;

    .dropdown-item {
        font-weight: 700;

        &:hover {
            background-color: unset;
            color: #101010;
            text-decoration: underline;
        }
    }
}

.bg-dark {
    background-color: #101010 !important;
}

.btn-login {
    background-color: black;
    color: white;
    border-radius: 0.75rem;
}
