.modal {
    .modal-header {
        justify-content: center;
        border-bottom: none;
    }

    .modal-footer {
        justify-content: center;
        border-top: none;
    }

    .modal-content {
        justify-content: center;
        border-radius: 1.5625rem;
    }

    .form-control {
        border: 0.0625rem solid black;
        border-radius: 0.75rem;
        color: black;

        &::placeholder {
            color: black;
        }
    }

    .btn-login {
        background-color: black;
        color: white;
        border-radius: 0.75rem;
    }

    .close, .close:hover {
        color: black;
    }

    a {
        color: black;
    }
}

.text-small {
    font-size: 0.75rem;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 1rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: black;
    border-color: black;
}

.ticket-modal {
    .close {
        color: white !important;

        &:hover {
            color: white !important;
            opacity: 1 !important;
        }
    }

    .modal-content {
        background-color: #101010;
        color: white;
        border: none;
    }

    .code-block {
        background-color: #fff;
        color: #101010;
        padding: 10px;
        margin-bottom: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-of-type {
            border-radius: 15px 15px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 15px 15px;
        }

        &__code {
            font-weight: bold;
            display: inline-block;
            margin-bottom: 0;
        }

        &__btn {
            margin-left: auto;
        }
    }
}

.performance-modal {
    .close {
        color: white;

        &:hover {
            color: white;
        }
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 50%;
            margin: 1.75rem auto;
        }
    }

    .modal-content {
        background: #232323;
        border: 2px solid rgb(255, 197, 39);
        box-shadow: 17px 22px 0px 0px rgba(255, 197, 39, 1);
        -webkit-box-shadow: 17px 22px 0px 0px rgba(255, 197, 39, 1);
        -moz-box-shadow: 17px 22px 0px 0px rgba(255, 197, 39, 1);
    }

    .performance-modal-btn {
        width: 80%;
        text-align: center;
        border: 1px solid rgb(255, 197, 39);
        background-color: rgb(255, 197, 39);
        border-radius: 20px 0 20px 20px;

        * {
            margin: 0 !important;
        }

        &:hover {
            text-decoration: none;


        }
    }
}
