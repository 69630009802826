.headline-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 40vw;
    min-height: 100vh;
    background-image: url("/site/img/headBg_tablet.jpg");
    background-size: cover;
    background-position: center;
    z-index: -30;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);

    @media (min-width: 1200px) {
        background-image: url("/site/img/headBg_desktop.jpg");
    }
}

.head-sub-wrapper {
    height: 100vh;
    z-index: -1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;

    .head-sub-top-block {
        position: absolute;
        top: 9vh;
        right: 1rem;
        width: 15rem;
        text-align: right;

        @media (min-width: 768px) {
            width: 30rem;
            justify-content: end;
        }
    }

    .head-sub-block {
        position: absolute;
        bottom: 18vh;
        right: 1rem;
        width: 15rem;
        text-align: right;

        > h2 {
            text-shadow: 3px 3px 6px #e7e7e7, 3px -3px 6px #e7e7e7,
            -3px -3px 6px #e7e7e7, -3px 3px 6px #e7e7e7;
        }

        @media (min-width: 768px) {
            width: 30rem;

        }
    }
}
