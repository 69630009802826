@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700,900&display=swap");

body {
    font-family: "Merriweather", serif;
}
.font-weight-light {
    font-weight: 200;
}
.font-weight-regular {
    font-weight: 400 !important;
}
.font-weight-bold {
    font-weight: 700;
}

.font-weight-black {
    font-weight: 900;
}

.section-heading {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 700;
    color: rgba($colorBlack, 0.85);
}
