.donation-container {
    background-color: #ebebeb;

    .btn {
        font-size: 24px;
        border-radius: 8px;
    }
}

.progress {
    background-color: white;
    -webkit-box-shadow: inset 2px 5px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 2px 5px 10px 0px rgba(0,0,0,0.75);
    box-shadow: inset 2px 5px 10px 0px rgba(0,0,0,0.75);
    .progress-bar{
        background-color: #55A2C2;
    }
}
.donate-img {


    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
    }
    @include media-breakpoint-up(xl) {
        max-width: 75%;
    }
    @media (min-width: 1450px) {
        max-width: 65%;
    }


}
