.video-library {
    .link-back {
        color: #101010;

        &:hover {
            color: #101010;
        }

        &--white {
            color: white;

            &:hover {
                color: white;
            }
        }
    }

    .category {
        img {
            max-height: 500px;
        }

        a {
            //color: #101010;

            &:hover {
                // color: #101010;
            }

            img {
                cursor: pointer;
                width: 100%;
                @include media-breakpoint-up(lg) {
                    width: 50%;
                }
            }
        }
    }

    .video-container {

        iframe {
            width: 100%;
            height: 100%;
        }

        .video-label-container {
            position: absolute;
            top: 0.3125rem;
            right: 0.3125rem;
            width: 15%;

            .vip-label {
                max-width: 100%;
                margin-bottom: 5px;
            }

            .new-label {
                max-width: 100%;

            }
        }


        .video-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            top: 0;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;

            &--hide {
                display: none;
            }
        }

        .video-thumb {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            cursor: pointer;
        }

        .embed-container {
            -webkit-box-shadow: 0.3125rem 0.3125rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.78);
            box-shadow: 0.3125rem 0.3125rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.78);
            border: 1px solid white;
        }
    }

    .embed-container {
        --video--width: 1280;
        --video--height: 720;

        position: relative;
        padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
        overflow: hidden;
        max-width: 100%;
        background: black;

    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.video-separator {
    border-bottom: 0.0625rem solid #797F84;
    padding: 0.125rem;
}

.swiper {
    width: 100%;
}

.swiper-button-next, .swiper-button-prev {
    color: #17272E !important;

}

.videolist-bg {
    background-image: url(../img/extra-bg.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.video-search-input {
    background-color: #17272E;
    border: none;
    padding: 0.5rem;
    color: #818689;
}

.video-search-btn {
    background-color: #17272E;
    border: none;
    color: #818689;
}

.video-list-container {
    flex-wrap: wrap;
}

.video-item {
    @include media-breakpoint-up(lg) {
        width: 20%;
        margin: 0.5rem;
    }
    width: 100%;

}

.more-videos-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #243237;
    height: 13.125rem;
    @include media-breakpoint-up(lg) {
        width: 12%;
        margin: 0.5rem;
    }
}

.js-video-category {
    cursor: pointer;
}

.video-category-select {
    background-color: #17272E;
    border: none;
    padding: 0.5rem;
    color: #818689;
    min-width: 10.9375rem;
    margin-left: 0.9375rem;
}
