.membership-profile {
    .card {
        min-height: unset;
        padding: 2rem;
        border-radius: 15px;

        .table td {
            vertical-align: middle;
        }

        .download-link {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: #101010;
            border-radius: 50%;
            text-align: center;

            &__icon {
                width: 1em;
                height: 1em;
                content: url("../img/download.svg");
                font-size: 1.2em;
                top: calc(50% - 10px);
                position: relative;

            }
        }
    }
}
