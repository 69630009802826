html {
    font-size: 12px;
    scroll-behavior: smooth;

    @media (min-width: 576px) {
        font-size: 13px;
    }

    @media (min-width: 768px) {
        font-size: 14px;
    }

    @media (min-width: 992px) {
        font-size: 15px;
    }

    @media (min-width: 1200px) {
        font-size: 16px;
    }
}

html, body {
    height: 100%;
}

body {
    background-color: #ebebeb !important;
    display: flex;
    flex-direction: column
}

main {
    overflow: hidden;
    flex: 1 0 auto;
}

footer {
    flex-shrink: 0;
}

.invalid-feedback {
    display: block;
    color: red;
}

.pieces-group {
    background-color: white;
    color: #101010;
    border-radius: 5px;

    label {
        padding: 0.375rem 1.75rem;
        margin-bottom: 0;
    }
}


