.subscribe-section-wrapper {
    background-color: $colorLightGray;
}

.subscribe-text-wrapper {
    max-width: 33rem;
}

.subscribe-patreon {
    margin-bottom: 0;
    line-height: 5rem;
    padding-right: 0.5rem;
}

.email {
    background-image: url("/site/img/mail.svg");
    background-size: auto;
    background-repeat: no-repeat;
    width: 3rem;
    height: 2.5rem;
    background-position: center;
}

.form-control-plaintext {
    border-bottom: 1px solid $colorBlack;
    color: $colorBlack;

    &::placeholder {
        color: rgba($colorBlack, 0.25);
    }
}

.form-control-plaintext:focus {
    outline: none;
    background-color: white;
}

#subscribe-page {
    .banner-sec {
        background-color: #ebebeb !important;
    }
}
