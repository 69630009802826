.lectures {
    .card {
        min-height: unset;
    }
}

.ticket-purchase-bg-image {
    color: #ebebeb;
    background-image: url(../img/mogacs_live.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;


}

.buy-ticket {
    float: right;

}

.lecture_desc_button {
    float: right;
    color: #101010;
    text-decoration: none;

    &:hover {
        color: #101010;
        text-decoration: none;
    }
}
