// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$colorBlack: #101010;
$colorLightGray: #ebebeb;
$colorDanger: #cd201f;

body {
    background-color: $colorLightGray;
}

.bg-light {
    background-color: $colorLightGray;
}

.bg-light-gray {
    background-color: #ebebeb !important;
}

.bg-black {
    background-color: $colorBlack;
}

.bg-black-0 {
    background-color: #000000;
}

.text-white {
    color: white;
}

.bg-blue-0 {
    background-color: #17272E;
}

.bg-light-gray-2 {
    background-color: #cfcfd1;
}

.text-black {
    color: #212529 !important;
}
.text-black-hover:hover {
    color: #212529 !important;
}
